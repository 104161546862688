<script>
export default {
  name: 'List',
  props: {
    value: {
      type: [Array],
      required: true,
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.value);
    },
  },
};
</script>

<template lang="pug">
span
  ul(v-if="isArray")
    li(v-for="item in value" :key="item") {{ item }}
  span(v-else) {{ value }}
</template>
